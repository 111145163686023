import { GatsbyBrowser, Script, ScriptStrategy } from 'gatsby';
import React from 'react';
import Layout from './src/components/Layout';

interface SharedWindow extends Window {
    provideGtmPlugin: any;
    dataLayer: any;
}

declare const window: SharedWindow;
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
    props,
}) => (
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    <Layout {...props}>
        {/* // GTM */}
        <Script
            strategy={ScriptStrategy.postHydrate}
            forward={[`dataLayer.push`, `fbq`]}
            dangerouslySetInnerHTML={{
                __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MQW9BV');
              `,
            }}
        />
        <Script
            strategy={ScriptStrategy.postHydrate}
            forward={[`dataLayer.push`, `fbq`]}
            dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PG8CN6C2');
              `,
            }}
        />

        {/* // IpMeta script tag added to handle bot traffic */}
        {/* <Script
            strategy={ScriptStrategy.postHydrate}
            src="https://ipmeta.io/plugin.js"
            onLoad={() => {
                const data = window.provideGtmPlugin({
                    gtmEventKey: 'ipmeta_loaded',
                    apiKey: '315055d6a28d914dad1b6e7970c98baa3ac2064f223801c2c8af00a269f02bf6907c625f57b7a130',
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(data);
            }}
        /> */}
        {element}

        {/* // Wistia */}
        <Script
            strategy={ScriptStrategy.postHydrate}
            type="text/javascript"
            src="https://fast.wistia.com/assets/external/E-v1.js"
            integrity="sha512-HAZQztyeiryRX2jJPfzzt51k576ROGpOZUv5bKOkEddAq/94qU7+mI/rtjRa6sRcBm0tFOPFQ/CbOrSNjBiVwg==" 
            crossOrigin="anonymous"
        />
        {/* // Infinity */}
        <Script
            strategy={ScriptStrategy.postHydrate}
            dangerouslySetInnerHTML={{
                __html: `
            var _ictt = _ictt || [];
            _ictt.push(['_setIgrp','2466']); // Installation ID
            _ictt.push(['_enableGAIntegration',{'gua':true,'ga':false}]);
            _ictt.push(['_enableAutoDiscovery']);
            _ictt.push(['_track']);
            _ictt.push(['_addCallback', storeNumberInCookie]);
            (function() {
                var ict = document.createElement('script'); ict.type = 'text/javascript'; ict.async = true;
                ict.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'ict.infinity-tracking.net/js/nas.v1.min.js';
                var scr = document.getElementsByTagName('script')[0]; scr.parentNode.insertBefore(ict, scr);
            })();
            function storeNumberInCookie() {
              setTimeout(function () {
                var el = document.getElementsByClassName("InfinityNumber__num");
                if (el && el.length) {
                  var value = el[0].innerText.replace(/[^0-9 ]/g, "");
                  if (value)
                    createCookie('icttNum', value, 1); // 1 day
                }
              }, 1000);
            }
            function createCookie(name, value, days) {
              var expires;
              if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toGMTString();
              }
              else {
                expires = '';
              }
              document.cookie = name + '=' + value + expires + '; path=/';
            }
              `,
            }}
        />
    </Layout>
);
